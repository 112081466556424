import React from "react";

const ResumeInterest = ({ className }) => {
  return (
    <div className={className}>
      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">My lovely cat</span>
        </div>

        <div className="detail__sub-heading">
          <span>Nothing can describe my love for her.</span>
        </div>
      </div>

      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">Cooking</span>
        </div>

        <div className="detail__sub-heading">
          <span>
            Besides coding, I find my trueself in the kitchen cooking meals for
            my loved ones.
          </span>
        </div>
      </div>

      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">Models and Puzzles</span>
        </div>

        <div className="detail__sub-heading">
          <span>
            I can spend hours challenging myself with 3D models and all kinds of
            puzzles. They boost my creativity and patient to the highest.
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResumeInterest;
