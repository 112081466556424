import "../assets/styles/ScreenHeading.scss";

const ScreenHeading = ({ title, subtitle }) => {
  return (
    <div className="heading">
      <div className="heading__title">
        <span>{title}</span>
      </div>

      {subtitle ? (
        <div className="heading__subtitle">
          <span>{subtitle}</span>
        </div>
      ) : (
        <div> </div>
      )}

      <div className="heading__separator">
        <div className="heading__separator--line">
          <div className="heading__separator--knot">
            <div className="heading__separator--knot--inner"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenHeading;
