import "../assets/styles/Projects.scss";
import projectList from "../assets/files/ProjectList";
import ProjectDetails from "./ProjectDetails";
import ScreenHeading from "./ScreenHeading";

const Projects = () => {
  return (
    <div className="projects" id="projects">
      <div className="projects__heading">
        <ScreenHeading title={"Projects"} subtitle={"My Projects"} />
      </div>

      {projectList.map((project) => (
        <ProjectDetails key={project.key} project={project} />
      ))}
    </div>
  );
};

export default Projects;
