import "../assets/styles/Profile.scss";

import Header from "./Header";

import TypeAnimation from "react-type-animation";
import { Fade } from "react-reveal";

import { FaGithub, FaLinkedin, FaEnvelope, FaFileAlt } from "react-icons/fa";

const Profile = () => {
  return (
    <div className="container" id="home">
      <Header />

      <div className="profile">
        <div className="profile__details">
          <div className="profile__details--socials">
            <a
              href="https://github.com/HelenQuang"
              target="_blank"
              rel="noopener noreferrer"
              title="Github Profile"
            >
              <FaGithub />
            </a>
            <a
              href="https://www.linkedin.com/in/ha-quang-helen/"
              target="_blank"
              rel="noopener noreferrer"
              title="LinkedIn Profile"
            >
              <FaLinkedin />
            </a>
            <a
              href="mailto:qvha.97@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              title="Email"
            >
              <FaEnvelope />
            </a>
            <a
              href="https://www.canva.com/design/DAFFHm4bHvs/RAZx_nbhTmt8dcphZFjoew/view?utm_content=DAFFHm4bHvs&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
              target="_blank"
              rel="noopener noreferrer"
              title="Online Resume"
            >
              <FaFileAlt />
            </a>
          </div>

          <Fade ssrFadeout bottom delay={600} distance="30px">
            <Fade ssrFadeout delay={700} left distance="30px">
              <div className="profile__details--name">
                <span>
                  Hi, I'M{" "}
                  <span className="profile__details--name--highlighted">
                    HELEN QUANG,
                  </span>
                </span>
              </div>
              <div className="profile__details--name">
                <span className="profile__details--name--highlighted">
                  A FULL-STACK WEB DEVELOPER.
                </span>
              </div>
              <div className="profile__details--roles">
                But you can also call me{" "}
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    "a Loyal Cat Servant.",
                    6000,
                    "an Ambitious Home Cook.",
                    6000,
                    "a Team Player with an eye for detail.",
                    6000,
                  ]}
                  repeat={Infinity}
                  className="profile__details--roles--animation"
                />
                <div className="profile__details--roles--tagline">
                  <p>I #design what I #love !</p>
                  <p>I #develop what I #like !</p>
                </div>
              </div>
            </Fade>

            <Fade ssrFadeout left delay={800} distance="30px">
              <div className="profile__details--buttons">
                <a href="#contact">
                  <button className="btn primary-btn">Contact Me</button>
                </a>

                <a
                  href="https://www.canva.com/design/DAFFHm4bHvs/RAZx_nbhTmt8dcphZFjoew/view?utm_content=DAFFHm4bHvs&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Online Resume"
                >
                  <button className="btn highlighted-btn">Get My Resume</button>
                </a>
              </div>
            </Fade>
          </Fade>
        </div>

        <Fade ssrFadeout bottom delay={600} distance="30px">
          <div className="profile__picture">
            <div className="profile__picture--bg"></div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Profile;
