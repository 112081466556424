import "../assets/styles/Resume.scss";

const ResumeEducation = ({ className }) => {
  return (
    <div className={className}>
      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">
            Coding &amp; Finnish Language Training Program
          </span>
          <div className="detail__main-heading--date">04.2023 - 12.2023</div>
        </div>

        <div className="detail__sub-heading">
          <span>BearIT Oy</span>
        </div>
      </div>
      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">
            Jyväskylä University of Applied Sciences
          </span>
          <div className="detail__main-heading--date">2022 - ongoing</div>
        </div>

        <div className="detail__sub-heading">
          <span>
            Master Degree of Information Technology, Full-stack Development
          </span>
        </div>
      </div>
      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">
            Tampere University of Applied Sciences
          </span>
          <div className="detail__main-heading--date">2016 - 2020</div>
        </div>

        <div className="detail__sub-heading">
          <span>Bachelor Degree in Energy &amp; Environmental Engineering</span>
        </div>
      </div>

      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">Self Learning</span>
          <div className="detail__main-heading--date">2021 - NOW</div>
        </div>

        <div className="detail__sub-heading">
          <span>
            Two years experience in ReactJS, TypeScript, MERN Stack and more
          </span>
        </div>
      </div>

      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">Current Learning</span>
        </div>

        <div className="detail__sub-heading">
          <span>AWS, MySQL, DevOps, CI/CD, Cypress</span>
        </div>
      </div>
    </div>
  );
};

export default ResumeEducation;
