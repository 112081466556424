import Tilt from "react-parallax-tilt";
import { Fade } from "react-reveal";

const ProjectDetails = ({ project }) => {
  const { title, description, image, source, live, technologies, github } =
    project;

  return (
    <div className="project">
      <Fade ssrFadeout delay={600} left distance="30px">
        <div className="project__description">
          <h3 className="project__description--title">{title}</h3>
          <p className="project__description--text">{description}</p>

          {technologies && (
            <ul className="project__description--tech">
              {technologies.map((tech) => {
                return <li key={tech} className="tech">{`#${tech}`}</li>;
              })}
            </ul>
          )}

          {source && live && (
            <div className="project__description--buttons">
              <a rel="noreferrer" target="_blank" href={live}>
                <button className="btn highlighted-btn">See Live</button>
              </a>

              <a rel="noreferrer" target="_blank" href={source}>
                <button className="btn primary-btn">Source Code</button>
              </a>
            </div>
          )}

          {github && (
            <div className="project__description--buttons">
              <a rel="noreferrer" target="_blank" href={github}>
                <button className="btn primary-btn">My Github Profile</button>
              </a>
            </div>
          )}
        </div>
      </Fade>

      {image && (
        <Fade ssrFadeout delay={600} right distance="30px">
          <div className="project__img">
            <a rel="noreferrer" href={live} target="_blank">
              <Tilt
                data-tilt
                tiltMaxAngleX="2"
                tiltMaxAngleY="2"
                glareEnable="true"
                glareMaxOpacity="0.5"
              >
                <img style={{ width: "100%" }} src={image} alt={title} />
              </Tilt>
            </a>
          </div>
        </Fade>
      )}
    </div>
  );
};

export default ProjectDetails;
