import "../assets/styles/Footer.scss";

import { BsArrowUpSquareFill } from "react-icons/bs";
import { FaGithub, FaLinkedin, FaEnvelope, FaFileAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__scroll">
        <a href="#home" title="Scroll to top">
          <BsArrowUpSquareFill style={{ width: "3rem", height: "3rem" }} />
        </a>
      </div>

      <div className="footer__socials">
        <a
          href="https://github.com/HelenQuang"
          target="_blank"
          rel="noopener noreferrer"
          title="Github Profile"
        >
          <FaGithub style={{ width: "2rem", height: "2rem" }} />
        </a>
        <a
          href="https://www.linkedin.com/in/ha-quang-helen/"
          target="_blank"
          rel="noopener noreferrer"
          title="LinkedIn Profile"
        >
          <FaLinkedin style={{ width: "2rem", height: "2rem" }} />
        </a>
        <a
          href="mailto:qvha.97@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
          title="Email"
        >
          <FaEnvelope style={{ width: "2rem", height: "2rem" }} />
        </a>
        <a
          href="https://www.canva.com/design/DAFFHm4bHvs/cP5NKlKbsh0uXemOO6F9Lg/view?utm_content=DAFFHm4bHvs&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"
          target="_blank"
          rel="noopener noreferrer"
          title="Online Resume"
        >
          <FaFileAlt style={{ width: "2rem", height: "2rem" }} />
        </a>
      </div>

      <p>Built by Helen Quang - {new Date().getFullYear()}</p>
    </div>
  );
};

export default Footer;
