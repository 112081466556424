import "../assets/styles/About.scss";
import ScreenHeading from "./ScreenHeading";
import { Fade } from "react-reveal";

import HelloGIF from "../assets/images/profile/hello.gif";

const About = () => {
  return (
    <div className="about" id="about">
      <ScreenHeading title={"About Me"} subtitle={"My Story"} />

      <Fade ssrFadeout delay={600} bottom distance="30px">
        <div className="about__container">
          <div className="about__container--img">
            <img src={HelloGIF} alt="Hello GIF" />
            <div className="about__container--buttons">
              <a href="#contact">
                <button className="btn primary-btn">Contact Me</button>
              </a>

              <a
                href="https://www.canva.com/design/DAFFHm4bHvs/RAZx_nbhTmt8dcphZFjoew/view?utm_content=DAFFHm4bHvs&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"
                target="_blank"
                rel="noopener noreferrer"
                title="Online Resume"
              >
                <button className="btn highlighted-btn">Get My Resume</button>
              </a>
            </div>
          </div>

          <div className="about__container--details">
            <p>
              As a full-stack developer, I have experience working with both
              front-end and back-end technologies. I enjoy working on both sides
              of the stack and appreciate the challenges that come with building
              robust, scalable applications.
            </p>

            <p>
              I started coding when I was at the university and I fell in love
              with it ever since. My journey included a lot of self-studying,
              reading documentations, and creating projects.
            </p>

            <p>
              I am always eager to learn new things and keep up with the latest
              trends and best practices in the development world. I am confident
              in my ability to work collaboratively with other developers, and I
              am committed to delivering quality work on time.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default About;
