import { useState } from "react";
import { Fade } from "react-reveal";

import ScreenHeading from "./ScreenHeading";
import ResumeEducation from "./ResumeEducation";
import ResumeSkill from "./ResumeSkill";
import ResumeInterest from "./ResumeInterest";

import education from "../assets/images/media/education.svg";
import skill from "../assets/images/media/programming-skills.svg";
import interest from "../assets/images/media/interests.svg";

import "../assets/styles/Resume.scss";

const Resume = () => {
  const [labelActive, setLabelActive] = useState("education");
  const [detailsActive, setDetailsActive] = useState("education");

  return (
    <div className="resume" id="resume">
      <div className="resume__heading">
        <ScreenHeading title={"Resume"} subtitle={"My Personal Details"} />
      </div>

      <Fade ssrFadeout delay={600} bottom distance="30px">
        <div className="resume__card">
          <div className="resume__card--section-bullets">
            <ul className="logo">
              <li>
                <img src={education} alt="education logo" />
              </li>

              <li>
                <img src={skill} alt="skill logo" />
              </li>

              <li>
                <img src={interest} alt="interest logo" />
              </li>
            </ul>

            <ul className="labels">
              <li
                className={`${labelActive === "education" ? "active" : ""}`}
                onClick={() => {
                  setLabelActive("education");
                  setDetailsActive("education");
                }}
              >
                Education
              </li>
              <li
                className={`${labelActive === "skills" ? "active" : ""}`}
                onClick={() => {
                  setLabelActive("skills");
                  setDetailsActive("skills");
                }}
              >
                Programming Skills
              </li>
              <li
                className={`${labelActive === "interests" ? "active" : ""}`}
                onClick={() => {
                  setLabelActive("interests");
                  setDetailsActive("interests");
                }}
              >
                Interests
              </li>
            </ul>
          </div>

          <div className="resume__card--section-details">
            <ResumeEducation
              className={`${
                detailsActive === "education" ? "details_active" : "details"
              }`}
            />

            <ResumeSkill
              className={`${
                detailsActive === "skills" ? "details_active" : "details"
              }`}
            />

            <ResumeInterest
              className={`${
                detailsActive === "interests" ? "details_active" : "details"
              }`}
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Resume;
