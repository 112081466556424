import "../assets/styles/Resume.scss";

const ResumeSkill = ({ className }) => {
  return (
    <div className={className}>
      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">Front-end:</span>
        </div>

        <div className="detail__sub-heading">
          <span>
            HTML, CSS, JavaScript, ReactJS, TypeScript, Redux, React Query,
            Apollo Client
          </span>
        </div>
      </div>

      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">Back-end:</span>
        </div>

        <div className="detail__sub-heading">
          <span>NodeJS, ExpressJS, NextJS, NestJS, REST API, GraphQL</span>
        </div>
      </div>

      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">Database:</span>
        </div>

        <div className="detail__sub-heading">
          <span>MongoDB, Firebase</span>
        </div>
      </div>

      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">Testing:</span>
        </div>

        <div className="detail__sub-heading">
          <span>React Testing Library, Jest</span>
        </div>
      </div>

      <div className="detail">
        <div className="detail__main-heading">
          <div className="detail__main-heading--dot"></div>
          <span className="detail__main-heading--text">User Interface:</span>
        </div>

        <div className="detail__sub-heading">
          <span>SASS, Bootstrap, Material UI, Tailwind CSS</span>
        </div>
      </div>
    </div>
  );
};

export default ResumeSkill;
