import ScreenHeading from "./ScreenHeading";

import "../assets/styles/Contact.scss";
import { FaRegPaperPlane } from "react-icons/fa";
import { Fade } from "react-reveal";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <div className="contact__heading">
        <ScreenHeading
          title={"Contact Me"}
          subtitle={"Let me buy you a cup of coffee"}
        />
      </div>

      <Fade ssrFadeout delay={600} bottom distance="30px">
        <div className="contact__card">
          <p>
            I'm always happy to meet and work with new people. If you want to
            discuss about partnership, collaboration or just chat as friend,
            shoot me an email directly on <strong>qvha.97@gmail.com</strong>.
          </p>

          <div className="contact__card--form">
            <input
              type="text"
              placeholder="Name"
              aria-required="true"
              required
            />
            <input
              type="text"
              placeholder="Email"
              aria-required="true"
              required
            />
            <input
              type="text"
              placeholder="Message"
              style={{ paddingBottom: "5rem" }}
              aria-required="true"
              required
            />

            <a rel="noopener noreferrer" target="_blank" href="#resume">
              <button type="submit" className="btn highlighted-btn">
                <FaRegPaperPlane />
                <span>Send</span>
              </button>
            </a>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Contact;
