import Loader from "./components/Loader.jsx";
import Profile from "./components/Profile.jsx";
import About from "./components/About.jsx";
import Resume from "./components/Resume.jsx";
import Projects from "./components/Projects.jsx";
import Contact from "./components/Contact.jsx";
import Footer from "./components/Footer.jsx";

function App() {
  return (
    <div>
      <Loader />

      <Profile />
      <About />
      <Resume />
      <Projects />
      <Contact />

      <Footer />
    </div>
  );
}

export default App;
