import { useState } from "react";

import "../assets/styles/Header.scss";
import logo from "../assets/images/profile/HQlogo.png";

import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <div className="header">
      <div className="header__logo">
        <img className="header__logo" src={logo} alt="Ha Quang Logo" />
      </div>

      <div className="header__details">
        <a href="#home">Home</a>
        <a href="#about">About Me</a>
        <a href="#resume">Resume</a>
        <a href="#projects">Projects</a>
        <a href="#contact">Contact Me</a>
      </div>

      <div className="header__shortcut">
        <FaBars
          style={{ width: "2rem", height: "2rem" }}
          onClick={() => {
            setNavOpen(true);
          }}
        />
      </div>

      {navOpen && (
        <div className="header__shortnav">
          <IoClose
            style={{ width: "3rem", height: "3rem", cursor: "pointer" }}
            className="close-btn"
            onClick={() => {
              setNavOpen(false);
            }}
          />
          <a href="#home">Home</a>
          <a href="#about">About Me</a>
          <a href="#resume">Resume</a>
          <a href="#projects">Projects</a>
          <a href="#contact">Contact Me</a>
        </div>
      )}
    </div>
  );
};

export default Header;
