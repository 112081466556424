import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";

import "../assets/styles/Loader.scss";
import Logo from "../assets/images/profile/HQlogo.png";

const Loader = () => {
  const [value, setValue] = useState(0);
  const loading = useRef();
  const loadingBar = useRef();
  const loadingText = useRef();
  const loadingCounter = useRef();
  const loadingBox = useRef();
  const loadingSVG = useRef();

  useEffect(() => {
    if (value === 100) {
      return;
    }

    const intervalFn = setInterval(
      () => setValue((oldValue) => oldValue + 1),
      30
    );

    return () => clearInterval(intervalFn);
  }, [value]);

  const innerBarWidth = value + "%";

  useEffect(() => {
    if (value === 100) {
      gsap.to(loadingBar.current, {
        duration: 5,
        rotation: "90deg",
        left: "1000%",
      });
      gsap.to(loadingText.current, {
        duration: 1,
        opacity: 0,
      });
      gsap.to(loadingCounter.current, {
        duration: 0.5,
        opacity: 0,
      });
      gsap.to(loadingBox.current, {
        duration: 1,
        height: "31rem",
        borderRadius: "50%",
        border: "3px solid rgba(0, 0, 0, 0.5)",
      });
      gsap.to(loadingSVG.current, {
        duration: 7,
        opacity: 1,
        rotateY: "90deg",
      });
      gsap.to(loadingBox.current, {
        delay: 5,
        border: "none",
      });
      gsap.to(loading.current, {
        delay: 2,
        duration: 2,
        backgroundColor: "#f7e9cf",
        opacity: 0,
        zIndex: -1,
      });
    }
  });

  return (
    <div className="loading" ref={loading}>
      <div className="loading__box" ref={loadingBox}>
        <img src={Logo} alt="logo" className="loading__svg" ref={loadingSVG} />
        <div className="loading__text" ref={loadingText}>
          <div className="loading__text--border loading__width_animation"></div>
          L<div className="loading__text--dot">O</div>
          ADING EXPERIENCE
        </div>
        <div className="loading__bar" ref={loadingBar}>
          <div
            className="loading__bar--inner"
            style={{ width: `${innerBarWidth}` }}
          ></div>
        </div>
        <div className="loading__counter" ref={loadingCounter}>
          <span>{value + "%"}</span>
          <div className="loading__counter--percentage">100%</div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
