import Solmate from "../images/projects/Solmate.png";
import FoodOrdering from "../images/projects/Food.png";
import HouseMarketplace from "../images/projects/House.png";
import Romand from "../images/projects/Romand.png";
import Birnest from "../images/projects/BirdnestDesktop.png"
import Eurojackpot from "../images/projects/EurojackpotDesktop.png"

const projectList = [
  {
    title: "Eurojackpot Simulator Project",
    key: "1",
    description:
      "This project is a full-stack web app that simulates the Eurojackpot lottery, using NodeJS, Express, MongoDB, React, Typescript, Material UI, and Stripe Payment. The app allows users to purchase lottery tickets online and enter the draw. The algorithm generates winning numbers and determines winners based on the rules of Eurojackpot. NodeJS and Express provide a RESTful API for the app's backend, handling user authentication, ticket purchases, and lottery draw processing. MongoDB stores user and ticket information, as well as lottery results. The frontend is built with React and Typescript, with Material UI designing the interface. Stripe Payment in Test mode ensures secure transactions. Enjoy the lottery experience from home with our reliable and user-friendly app.",
    image: Eurojackpot,
    source: "https://github.com/HelenQuang/Eurojackpot-Simulator",
    live: "https://jamk-eurojackpot-simulator.onrender.com/",
    technologies: [
      "NodeJS",
      "ExpressJS",
      "MongoDB",
      "React",
      "Typescript",
      "Material UI",
      "Stripe Payment Test mode"
    ],
  },
  {
    title: "Reaktor Birdnest Project",
    key: "2",
    description:
      "This website is used to show up-to-date information about the drones and the pilots that are violating the conservative area of the Monadikuikka birdnest. These drones are flying within a 100-meter-radius calculated from the birdnest coordinates.",
    image: Birnest,
    source: "https://github.com/HelenQuang/Reaktor-Pre-assignment-2023",
    live: "https://birdnest-project-helen.onrender.com/",
    technologies: [
      "NodeJS",
      "ExpressJS",
      "MongoDB",
      "React",
      "Typescript",
      "Material UI",
    ],
  },
  {
    title: "Solmate Ecommerce",
    key: "3",
    description:
      "This online jewelry shop is built on a cutting-edge technology stack using MongoDB, ExpressJS, React, and NodeJS. These technologies allow us to provide a fast and secure shopping experience for our customers. With MongoDB, we can store and retrieve product information quickly and efficiently, allowing customers to browse our extensive inventory with ease. ExpressJS provides a robust backend for user authentication and order processing. React offers a user-friendly interface that makes it easy to browse products and complete purchases. Finally, NodeJS enables us to create scalable and efficient web applications that can handle high traffic volumes without sacrificing performance.",
    image: Solmate,
    source: "https://github.com/HelenQuang/Solmate-Ecommerce.git",
    live: "https://solmate-ecommerce-helen.onrender.com/",
    technologies: ["MERN Stack", "TypeScript", "Redux", "Bootstrap", "Paypal Payment Test mode"],
  },
  {
    title: "House Marketplace",
    key: "4",
    description:
      "Our house marketplace is a user-friendly web app that lets users buy, rent, release, or sell properties using ReactJS, Firebase, Firestore, and Google OAuth. Users can view detailed information about each property, including photos, descriptions, and features. To rent or buy a property, users create an account and provide personal information. Google OAuth ensures secure and seamless authentication. Authenticated users can also register their own properties for rent or sale, providing details, photos, and price.",
    image: HouseMarketplace,
    source: "https://github.com/HelenQuang/House-Marketplace-App.git",
    live: "https://house-marketplace-helen.netlify.app/",
    technologies: ["ReactJS", "Firebase", "Firestore", "Google OAuth"],
  },
  {
    title: "Food Order",
    key: "5",
    description:
      "This Asian food ordering website allows users to easily order delicious Asian food from home. Built using ReactJS, Firestore, Context API, and Module CSS, the website provides a simple and intuitive interface for browsing the menu and customizing orders. Using Firestore, we can securely store menu information and order details in the cloud for easy management and updates. With Context API for state management and Module CSS for styling, the application is scalable, easy to maintain, and visually consistent. Whether you're in the mood for Pho soup or noodle, our website has you covered!",
    image: FoodOrdering,
    source: "https://github.com/HelenQuang/Food-Ordering.git",
    live: "https://food-ordering-helen.netlify.app/",
    technologies: ["ReactJS", "Firestore", "Context API", "Module CSS"],
  },
  {
    title: "Romand Ecommerce",
    key: "6",
    description:
      "This e-commerce website allows users to easily purchase a variety of makeup items online. Users can browse products, view details, and securely checkout using Paypal Sandbox, which simulates the payment process, allowing for easy testing of payment transactions without using actual payment details. The website is built with modern technologies (ReactJS, MongoDB, NodeJS) and includes features such as user authentication, order tracking, and a wishlist. Users can create an account, which stores their personal and payment information securely. They can also view their order history and track the status of their current orders.",
    image: Romand,
    source: "https://github.com/HelenQuang/Romand-Makeup-Ecommerce-App.git",
    live: "https://romand-ecommerce-helen.netlify.app/",
    technologies: [
      "ReactJS",
      "Firebase",
      "Firestore",
      "Context API",
      "Google OAuth",
      "Paypal Payment Test mode",
    ],
  },
  {
    title: "Want to know more of my works?",
    key: "7",
    description: "Please visit my Github Profile.",
    github: "https://github.com/HelenQuang",
  },
];

export default projectList;
